#calendar {
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
  position: relative;
}

.calendar-month {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-month div:first-child() {
  padding: 0.1em;
}

.calendar-month > div:nth-child(-n + 7) {
  color: black;
  background-color: #81ecec;
}

.calendar-month .today {
  background-color: #fab1a0;
  border-radius: 50%;
  color: black;
}

.badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.badge {
  background-color: #81ecec;
  border-radius: 50%;
  font-size: 25%;
  color: black;
  width: 50%;
  padding: 0.25em 0.15em;
  position: absolute;
  left: 60%;
  top: 50%;
}

/* Image Gallery */
.p-splide__content {
  padding-top: 0.5em;
  padding-bottom: 1.75em;
}

.splide__pagination__page.is-active {
  background: var(--color);
}